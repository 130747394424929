import React, { Component, useState, useEffect, useContext, useReducer, useMemo, useRef, lazy, Suspense, useCallback } from 'react';
import { NavLink, Outlet, Routes, Route, useParams, useSearchParams, useLocation, useNavigate } from 'react-router-dom'; // useHistory
import ReactDOM from 'react-dom/client';
import { useMediaQuery } from 'react-responsive';

/*
ЗАМЕТКА НА БУДУЩЕЕ:
1. Сервис https://ipapi.co/json/ НЕ ИСПОЛЬЗОВАТЬ из-за блокировок.
2. Удалить закоментированную секцию футер в нижней части кода при следующей версии.
3. Подключить отслеживание посещаемости на свой бэкэнд.
4. Окончательно убрать лишний закоментированный код.
*/

/* --------------------------- That's global State storage --------------------------- */
import selectContext from './components/extension/SelectContext';
// And then for use add in component: import SelectContext from './SelectContext';
// And then for use add in component: const { value } = useContext(SelectContext);

/* --------------------------- That's default "Alert" component --------------------------- */
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
/* Use for show information: toast.info("text"), toast.warn("text"), toast.error("text"), toast.success("text"); */
/* Use: <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" /> */

/* --------------------------- That's default "Loader spinner" component --------------------------- */
import { Audio } from 'react-loader-spinner';
/* Use: {loading && <Audio className="loader" height="80" width="80" radius="9" color="green" ariaLabel="loading" />} */
import Loader from './components/extension/Loader_v.0.5';

/* --------------------------- That's styles --------------------------- */
import './sass/main.css';
import Sprite from './components/extension/Sprite';


/* --------------------------- That's Components --------------------------- */
// import Contracts from './components/Contracts';
// import About from './components/About';
// import Error from './components/Error';
// import Provisions from './components/Provisions';
import Celebrities from './components/Celebrities';
import Footer from "./components/Footer";

// import Music from './components/extension/Music';
// import RevolveMap from "./components/extension/RevolverMap";
import Script from "./components/extension/Script_v.0.2";
import Modal from "./components/extension/Modal";
import Modal_video from "./components/extension/Modal_video";
import Theme from './components/extension/Theme_v.0.5';
import { Language, LanguageContext } from './components/extension/Language_v.0.7';
import sendTelegram from './components/extension/TelegramSend_v.0.2';
import DataLoader from './components/hooks/DataLoader';
import { CurrentDateTime, FormDateTime } from './components/extension/CurrentDateTime_v.0.4';
import Share from './components/extension/Share_v.0.2';


// ====================== Используем lazy для загрузки компонентов ======================
const Music = lazy(() => import('./components/extension/Music'));
const AdminPanel = lazy(() => import('./components/AdminPanel'));
const About = lazy(() => import('./components/About'));
const Contracts = lazy(() => import('./components/Contracts'));
const Provisions = lazy(() => import('./components/Provisions'));
const Error = lazy(() => import('./components/Empty'));
const Form = lazy(() => import('./components/Form')); 

// ====================== Формирование компонентов по категориям ======================

// const Categories = lazy(() => import('./components/celebrities/Categories'));
  import Categories from './components/celebrities/Categories';
  // const categoriesData = await DataLoader("categories");
  // console.log(categoriesData);


/* --------------------------- Global Application --------------------------- */

  const settings = await DataLoader("settings");
  const version = await settings.version;
  const newUsersNotification = await settings.newUserNotification; // Уведомление о новых пользователях.
  const base_url = await settings.baseURL;
  const showProductionMode = settings.showProductionMode;
  
  // Сервис https://ipapi.co/json/ НЕ ИСПОЛЬЗОВАТЬ из-за блокировок.
  // import getIP from './components/hooks/IP';
  // let resultIP = "Unknown";
  // if (settings.showUserIP) {
  //   resultIP = await getIP();
  // };



const Applicatiion = () => {

  /* That's link on bacic page */
  const location = useLocation();
  // console.log("Location URL:", location);

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);  // Отслеживаем загрузку
  const [select, setSelect] = useState(null);
  const [selectVideo, setSelectVideo] = useState(null);
  const [language, setLanguage] = useState("EN");
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const [categoriesData, setCategoriesData] = useState([]);

  const [currentURL, setCurrentURL] = useState(window.location.href);
  const [shareData, setShareData] = useState({
    title: 'OVCHARUK Company',
    text: 'OVCHARUK Company - watch and jewelry company with create unique exclusive personalized watches and jewelry of the most famous world celebrities.',
    url: window.location.href || base_url,
    // files: null, // Передача логотипа как файла
  });


  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const isShow = useMediaQuery({ minWidth: 840 });

  // -------------------- LANGUAGE ---------------------

  const [text, setText] = useState({
    news: "News",
    signed: "Signed contracts",
    provisions: "Provisions of the GFWC",
    about: "About company",
    contact: "Contact information:",
    telephone: "Telephone:",
    mail: "E-mail:",
    facebook: "Facebook:",
    creator: `Website (version: ${version}) made by Anatolii Ovcharuk - younger (Link on Github).`,
    theme: "Change theme",
    lang_inf: "You are set language: English (EN)",
    wa: "Send message on ",
    loading: "Loading...",
    rights: "Copyrignt © Anatoliy Ovcharuk, All rights reserved.",
    celebrities: "World's Celebrities",

    qr: "Open QR Code for open this Website on telephone.",
    th: "Set light or dark theme on website.",
    mu: "ON / OFF Music on background.",
    li: "Open link on Github.",
    sh: 'Share website.',
    form: "Open callback form.",
    share_text: "OVCHARUK Company - watch and jewelry company with create unique exclusive personalized watches and jewelry of the most famous world celebrities.",


  });

  useEffect(() => {
    if (language === "EN") {
      setText({
        news: "News",
        signed: "Signed contracts",
        provisions: "Provisions of the GFWC",
        about: "About company",
        contact: "Contact information:",
        telephone: "Telephone:",
        telegram: "Telegram:",
        mail: "E-mail:",
        facebook: "Facebook:",
        creator: `Website (version: ${version}) made by Anatolii Ovcharuk - younger (Link on Github).`,
        theme: "Change theme",
        lang_inf: `Set language: English (${language})`,
        wa: "Send message on ",
        loading: "Loading...",
        rights: "Copyrignt © Anatoliy Ovcharuk, All rights reserved.",
        celebrities: "World's Celebrities",

        qr: "Open QR Code for open this Website on telephone.",
        th: "Set light or dark theme on website.",
        mu: "ON / OFF Music on background.",
        li: "Open link on Github.",
        sh: 'Share website.',
        form: "Open callback form.",
        share_text: "OVCHARUK Company - watch and jewelry company with create unique exclusive personalized watches and jewelry of the most famous world celebrities.",


      });
    } else if (language === "RU") {
      setText({
        news: "Новости",
        signed: "Подписанные контракты",
        provisions: "Положение о ЗФЗМ",
        about: "О компании",
        contact: "Контактная информация:",
        telephone: "Номер телефона:",
        telegram: "Телеграм:",
        mail: "Электронная почта:",
        facebook: "Фейсбук:",
        creator: `Вебсайт (версия: ${version}) создал Анатолий Овчарук - младший (Ссылка на Гитхаб).`,
        theme: "Сменить тему",
        lang_inf: `Выбран язык: Русский (${language})`,
        wa: "Отправить сообщение на ",
        loading: "Загрузка...",
        rights: "Авторское право © Анатолий Овчарук, все права защищены.",
        celebrities: "Знаменитости Мира",
 
        qr: "Открыть QR код для открытия этого сайта на телефоне.",
        th: "Установить светлую или тёмную тему на сайте.",
        mu: "ВКЛ / ВЫКЛ Фоновую музыку.",
        li: "Открыть ссылку на Github.",
        sh: 'Поделиться сайтом.',
        form: "Открыть форму обращения.",
        share_text: "OVCHARUK Company - часовая и ювелирная компания, целью деятельности которой является создание уникальных эксклюзивных именных часов и ювелирных изделий знаменитостей мира.",
      

 
      });
    } else {
      setText({
        news: "News",
        signed: "Signed contracts",
        provisions: "Provisions of the GFWC",
        about: "About company",
        contact: "Contact information:",
        telephone: "Telephone:",
        telegram: "Telegram:",
        mail: "E-mail:",
        facebook: "Facebook:",
        creator: `Website (version: ${version}) made by Anatolii Ovcharuk - younger (Link on Github).`,
        theme: "Change theme",
        lang_inf: `Set language: English (${language})`,
        wa: "Send message on ",
        loading: "Loading...",
        rights: "Copyrignt © Anatoliy Ovcharuk, All rights reserved.",
        celebrities: "World's Celebrities",

        qr: "Open QR Code for open this Website on telephone.",
        th: "Set light or dark theme on website.",
        mu: "ON / OFF Music on background.",
        li: "Open link on Github.",
        sh: 'Share website.',
        form: "Open callback form.",
        share_text: "OVCHARUK Company - watch and jewelry company with create unique exclusive personalized watches and jewelry of the most famous world celebrities.",


      });
    }

  }, [language]);
  
  useEffect(() => {
    // console.log("Current URL: " + `${base_url}${location.pathname}${location.search}${location.hash}`);
    // console.log("Location URL:", location);

    setCurrentURL(`${base_url}${location.pathname}${location.search}${location.hash}`);
    formShare().then(data => {
      setShareData(data);
    });

  }, [language, text.share_text, location, currentURL, window.location.href]);

  async function formShare() {
    try {
      // Загрузка логотипа в виде Blob-объекта
      const response = await fetch(process.env.PUBLIC_URL + `/img/png/Logo_original.png`);
      const blob = await response.blob();
      const file = new File([blob], 'logo.png', { type: blob.type });

      return {
        title: 'OVCHARUK Company',
        text: text.share_text,
        url: currentURL || window.location.href,
        files: file, // Передача логотипа как файла
      }
    } catch (error) {
      console.error('Error to add logo for share...', error);
      toast.error('Error to add logo for share...');
      return {
        title: 'OVCHARUK Company',
        text: text.share_text,
        url: currentURL || window.location.href,
      }
    }
  };

  /* That's global State storage */
  const [storage, setStorage] = useState({
    data: data,
    setData: setData,
    loading: loading,
    setLoading: setLoading,
    select: select,
    setSelect: setSelect,
    selectVideo: selectVideo,
    setSelectVideo: setSelectVideo,
    language: language,
    text: text,
    categoriesData: categoriesData,
    isVideoPlaying: isVideoPlaying,
    setIsVideoPlaying: setIsVideoPlaying,
    shareData
  });

  useEffect(() => {
    setStorage(prevStorage => ({
      ...prevStorage,
      language,
      text,
      loading,
      data,
      categoriesData,
      select,
      selectVideo,
      shareData
    }));
  }, [language, loading, data, select, selectVideo, categoriesData, text, shareData]);

  const getTimeZone = () => {
    const offset = -new Date().getTimezoneOffset(); // инвертируем знак
    const sign = offset >= 0 ? "+" : "-";
    const hours = Math.floor(Math.abs(offset) / 60);
    const minutes = Math.abs(offset) % 60;
    return `UTC${sign}${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
  };
    
  function sendData(obj) {
    if (newUsersNotification) {
      sendTelegram(`
      ♻ Новый посетитель! New visitor! ${window.location.href}
      \n██████████████████████
      \nNew user visit website. | Новый пользователь посетил вебсайт.
      \n📆 ${(new Date()).toLocaleString() || FormDateTime(true)}
      ${settings.showUserIP ? `\n🔎 ${resultIP}` : ``}
      \n#️⃣ ID Device: ${localStorage.getItem('deviceID') || "Unknown"}
      \n💻 Operation system user: ${navigator.oscpu || "Unknown"}.
      \n👔 Browser: ${navigator.userAgent || "Unknown"}.
      \n⌚ Time zone: ${Intl.DateTimeFormat().resolvedOptions().timeZone} | ${getTimeZone()}.
      \n🌐 Language system on client: ${navigator.language || "Unknown"}. 
      ${showProductionMode ? window.location.href.includes("localhost") ? `\n🚧 Production mode Enabled.` : `\n🚧 Production mode Disabled.` : ""}
      `);
      // sendTelegram(`
      // ♻ Новый посетитель! New visitor! 
      // \n██████████████████████
      // \nNew user visit website. | Новый пользователь посетил вебсайт.\n📆 ${(new Date()).toLocaleString() || FormDateTime(true)}\n🔎 ${settings.showUserIP ? `${obj.ip} | ${obj.org}` : `${obj.org}`}\n🔶 Link: https://en.ipshu.com/picture/${obj.ip}.png\n📌 Location: ${obj.city}, ${obj.region}, ${obj.timezone}, ${obj.country_name}.\n#️⃣ ID Device: ${localStorage.getItem('deviceID') || "Unknown"}\n💻 Operation system user: ${navigator.oscpu || "Unknown"}.\n👔 Browser: ${navigator.userAgent || "Unknown"}.\n⌚ Time zone: ${Intl.DateTimeFormat().resolvedOptions().timeZone} | ${getTimeZone()}.\n🌐 Language system on client: ${navigator.language || "Unknown"}. \n${showProductionMode ? window.location.href.includes("localhost") ? `\n🚧 Production mode Enabled.` : `\n🚧 Production mode Disabled.` : ""}\n⚜ WEB: ${window.location.href}
      // `);
    };
  }
    

  useEffect(() => {

    sendData(); // передаём данные

    // async function reciveData() {
    // try {
    //     const response = await fetch('https://app.ovcharuk.com/scripts/script-visitor.js');
    //     if (!response.ok) {
    //       throw new Error(`Ошибка: ${response.status} ${response.statusText}`);
    //     }
    //     const obj = await response.json();
    //     console.log(obj)
    //     // setData(obj);
    //     // sendData(obj); // передаём данные
    // } catch (error) {
    //     console.error("Ошибка при получении IP данных:", error);
    // };
    // };

    // reciveData();


    const fetchCategories = async () => {
      try {
        const data = await DataLoader("categories");
        // console.log(data); // Проверяем, что возвращает DataLoader
        setCategoriesData(data);
        // setCategoriesData((prev) => [...new Set([...prev, ...data])]);
      } catch (error) {
        console.error("Error loading categories:", error);
      }
    };

    fetchCategories();

    if (!localStorage.getItem('deviceID')) {
      const deviceID = 'device_' + Date.now(); // Генерация уникального ID по дате
      localStorage.setItem('deviceID', deviceID);
    }
    console.log("Device ID: ", localStorage.getItem('deviceID'));

    formShare().then(data => {
      setShareData(data);
    });

  }, []);

  

  // -------------------- RENDER ---------------------
  
  // const [renderCount, setRenderCount] = useState(0);
  // useEffect(() => {
  //   if (renderCount >= 4) {  // Появление сообщения на третьем рендере (индекс 2)
  //     toast.info(`${text.lang_inf}`);
  //     console.log(`${text.lang_inf}`);
  //   }
  //   // Увеличиваем счетчик рендеров после каждого рендера
  //   setRenderCount(prevCount => prevCount + 1);
  // }, [text]);
  
  // -------------------- LOADER ---------------------

  // Загрузчик

  // const loader = <div className='loader'>
  //   <Audio className='loader' height="80" width="80" radius="9" color="inherit" ariaLabel="loading" />
  //   {/* <p>{text.loading}</p> */}
  // </div>;

  const loader = <Loader time={400}
    symbol={<img style={{ margin: "10px" }} className='loader__img' width={"50px"} alt="." src={process.env.PUBLIC_URL + `/img/png/diamondBsmall.png`} />}
    total={4} size={60} reverse={false} />;

  
  // -------------------- Разметка ---------------------  
  
  const parts =
    <Suspense fallback={loader}>
      <Routes>
        {/* <Route path="/"> */}
        <Route path="/" index element={<About />} />
        <Route path="contracts" element={<Contracts />} />
        <Route path="provisions" element={<Provisions />} />
        <Route path="celebrities" element={<Celebrities />} >
          {categoriesData.map((item, index) =>
            <Route exact
              key={item.name} // Уникальный ключ для каждого маршрута
              path={`${item.name.toLowerCase()}`}
              element={<Categories nameData={item.name.toLowerCase()} smallFont={item.fontSmall} id={index} />} // Используем компонент как JSX
            />
          )}
        </Route>
        <Route path="admin" element={<AdminPanel />} />
        <Route path="form" element={<Form />} />
        {/* <Route path="about" element={<About />} /> */}
        {/* </Route> */}
        <Route path="*" element={<Error />}></Route>
      </Routes>
    </Suspense>;

  
  return (
    <>
      <selectContext.Provider value={{ storage }}>
        <LanguageContext.Provider value={{ language, setLanguage }}>
          <header className="header">
            <Sprite id="logo" />
            <nav>
              <ul>
                <li><NavLink className='mark' to="/">{text.about}</NavLink></li>
                <li><NavLink className='mark' to="contracts" state={{ from: location }}>{text.signed}</NavLink></li>
                <li><NavLink className='mark' to="provisions" state={{ from: location }}>{text.provisions}</NavLink></li>
                <li><NavLink className='mark' to="celebrities" state={{ from: location }}>{text.celebrities}</NavLink></li>
                {/* <li><NavLink className='mark' to="about" state={{ from: location }}>{text.about}</NavLink></li> */}
              </ul>
            </nav>
            <section className='settings'>
              <div className='buttons__pannel'>
                {isShow && !navigator.share ?
                  <span title={text.qr}
                    onClick={() => setSelect({
                      src: process.env.PUBLIC_URL + `/img/jpg/qr/QR_Code_Website_OVCHARUK_Company.jpg`,
                      alt: text.qr,
                    })}>
                    <Sprite id="icon-qrcode" /></span> : null}
                {isShow && navigator.share ?
                  <span title={text.sh}><Share data={shareData} /></span> : null
                }
                <span title={text.th}><Theme /></span>
                <span title={text.mu}><Music isVideoPlaying={isVideoPlaying} /></span>
              </div>
              <Language />
            </section>
          </header>
      
          <main className="main">
            {loading ? loader || <p className='loader_text'>{text.loading}</p> : parts}
          </main>
      
        <footer className="footer">
          <section className='buttons__pannel'>
            <NavLink className='mark' to="form" state={{ from: location }}>
              <span style={{display: "flex", alignItems: "center", justifyContent: "center"}} title={text.form}>
                <Sprite id="icon-chat" />
              </span>
            </NavLink>
            <span style={{display: "flex", alignItems: "center", justifyContent: "center"}} title={text.qr} onClick={() => setSelect({
              src: process.env.PUBLIC_URL + `/img/jpg/qr/QR_Code_Website_OVCHARUK_Company.jpg`,
              alt: text.qr,
            })}><Sprite id="icon-qrcode" /></span>
            {/* {navigator.share && <Share data={shareData} />} */}
            <span style={{display: "flex", alignItems: "center", justifyContent: "center"}} title={text.sh}><Share data={shareData} /></span>
          </section>
          <Footer />
        </footer>


          <ToastContainer position="bottom-right" autoClose={4000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme={document.body.getAttribute('data-theme') || 'light'} />
          <Modal_video content={selectVideo} />
        </LanguageContext.Provider>
      </selectContext.Provider>
      <Modal content={select} />
    </>
  );
};

// ЗАМЕНЕН НА КОМПОНЕНТ!
      // <footer className="footer">
      //     <h3>{text.contact}</h3>
      //   <address id='contacts'>
      //     <a className="contact" href="tel:+79902896767" target='_blank'><Sprite id="phone" /><p>{text.telephone} +7 (990) 289-67-67</p></a>
      //     <a className="contact" href="https://telegram.me/anatoliyovcharuk" target='_blank'><Sprite id="telegram" /><p>{text.telegram} @anatoliyovcharuk</p></a>
      //     <a className="contact" href="https://wa.me/79902896767" target='_blank'><Sprite id="whatsapp" /><p>{text.wa}WhatsApp</p></a>
      //     <a className="contact" href="mailto:anatoliyovcharuk@gmail.com" target='_blank'><Sprite id="mail" /><p>{text.mail} anatoliyovcharuk@gmail.com</p></a>
      //     <a className="contact" href="https://www.facebook.com/The-Golden-Fund-of-the-Worlds-Celebrities-105944930989343/" target='_blank'><Sprite id="facebook" /><p>{text.facebook} www.facebook.com/The-Golden-Fund-of-the-Worlds-Celebrities-105944930989343</p></a>
      //   </address>
        // <section className='buttons__pannel'>
        //   <NavLink className='mark' to="form" state={{ from: location }}>
        //     <span style={{display: "flex", alignItems: "center", justifyContent: "center"}} title={text.form}>
        //       <Sprite id="icon-chat" />
        //     </span>
        //   </NavLink>
        //   <span style={{display: "flex", alignItems: "center", justifyContent: "center"}} title={text.qr} onClick={() => setSelect({
        //     src: process.env.PUBLIC_URL + `/img/jpg/qr/QR_Code_Website_OVCHARUK_Company.jpg`,
        //     alt: text.qr,
        //   })}><Sprite id="icon-qrcode" /></span>
        //   {/* {navigator.share && <Share data={shareData} />} */}
        //   <span style={{display: "flex", alignItems: "center", justifyContent: "center"}} title={text.sh}><Share data={shareData} /></span>
        // </section>
      //   {/* <RevolveMap /> */}
      //     <p className='rights'>{text.rights}</p>
      //     <a className='creator' title={text.li} href='https://github.com/Anatolii-Ovcharuk' target='_blank'>{text.creator}</a>
      // </footer>

export default Applicatiion;

