import React, { Component, useState, useEffect, useContext, useReducer, useMemo, useRef, lazy, Suspense } from 'react';
import { NavLink, Outlet, Routes, Route, useParams, useSearchParams, useLocation } from 'react-router-dom';
import ReactDOM from 'react-dom/client';

import Sprite from './extension/Sprite'; /* Example for use: <Sprite id="logo" /> */
import SelectContext from './extension/SelectContext';
import DataLoader from './hooks/DataLoader.js'

    const contact = await DataLoader("contacts");

const Footer = () => { 

    const { storage } = useContext(SelectContext);

    return (
      <>
          <h3>{storage.text.contact}</h3>
            <address id='contacts'>
                {contact.telephone && <a className="contact" href={`tel:+${contact.telephone}`} target='_blank'><Sprite id="phone" /><p>{storage.text.telephone} {`+${contact.telephone}`}</p></a>}
                {contact.whatsapp && <a className="contact" href={`https://wa.me/${contact.whatsapp}`} target='_blank'><Sprite id="whatsapp" /><p>{storage.text.wa} WhatsApp</p></a>}
                {contact.telegram && <a className="contact" href={`https://telegram.me/${contact.telegram}`} target='_blank'><Sprite id="telegram" /><p>{storage.text.telegram} {`@${contact.telegram}`}</p></a>}
                {contact.email && <a className="contact" href={`mailto:${contact.email}`} target='_blank'><Sprite id="mail" /><p>{storage.text.mail} {`${contact.email}`}</p></a>}
                {contact.mail && <a className="contact" href={`mailto:${contact.mail}`} target='_blank'><Sprite id="mail" /><p>{storage.text.mail} {`${contact.mail}`}</p></a>}
                {contact.facebook && <a className="contact" href={`https://www.facebook.com/${contact.facebook}`} target='_blank'><Sprite id="facebook" /><p>{storage.text.facebook} {`https://www.facebook.com/${contact.facebook}`}</p></a>}
            </address>
        {/* <RevolveMap /> */}
          <p className='rights'>{storage.text.rights}</p>
          <a className='creator' title={storage.text.li} href='https://github.com/Anatolii-Ovcharuk' target='_blank'>{storage.text.creator}</a>
      </>
    );
};


export default Footer;
